@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navcustom{
    padding-bottom: 20px;
    padding-top: 24px;
}

.truncate {
  max-width: 150px;
  /* adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-footer-reset {
  display: table-row-group;
  /* This ensures that it behaves as a table row group */
  width: 100%;
  /* This forces the tfoot to expand */
}

.table-footer-reset td {
  display: table-cell;
  /* This ensures that the td behaves as table cell */
  width: auto;
  /* This allows the td to expand as needed */
}

.truncate {
  max-width: 250px;
  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thead-sticky {
  position: sticky;
  top: 80px;
  z-index: 10;
  background-color: #F9FAFB;
  /* Matches bg-gray-50 */
}

.spinner-border {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}